

@media (max-width: 768px) {
  #navbar-list {
    display: none;
  }
}


.grid-background::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  position: absolute;
  opacity: 0.025;
  z-index: 0;
  background-image: linear-gradient(
    to bottom, transparent, #1F2936 50%
  ), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='%23f1f5f9' %3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}